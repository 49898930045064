import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import InterviewButton from '../molecules/InterviewButton';

const Chatbot = () => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ backgroundColor: 'background.default', zIndex: '100' }}>
      <InterviewButton clickCallback={() => setOpen(true)} chatState={open}/>
      <Box sx={{ position: 'fixed', bottom: {xs: '0px', md: '20px' }, right: {xs: '0px', md: '20px' }, width: { xs: '100%', md: '500px' }, height: { xs: '60%', md: '500px'}, backgroundColor: 'white', zIndex: '100', display: open ? 'block' : 'none', overflow: 'hidden' }}>
        <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
          <Typography variant="h5" sx={{ display: 'inline' }}>Chat with Alex</Typography>
          <Button onClick={() => setOpen(false)} sx ={{ display: 'inline' }}>x</Button>
        </Box>
        <iframe title="chatbot" src="https://chat.noboa.me:8501/" style={{ width: '100%', height: '100%'}}></iframe>
      </Box>
    </Box>
  )
}

export default Chatbot;