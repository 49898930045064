import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const baseTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#576F72',
      light: '#788B8E',
      dark: '#3C4D4F',
      contrastText: '#fff'
    },
    secondary: {
      main: '#7D9D9C',
      light: '#97B0AF',
      dark: '#576D6D',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    background: {
      default: '#FFFFFF',
      paper: '#F0EBE3',
    },
    text: {
      primary: 'rgba(8,43,57,0.87)',
      secondary: 'rgba(8,43,57,0.54)',
      disabled: 'rgba(8,43,57,0.38)',
      hint: 'rgba(8,43,57,0.38)',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#fff'
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    divider: 'rgba(8,43,57,0.12)',
  },
  typography: {
    fontFamily: ['Quicksand', 'Montserrat', 'Roboto', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    htmlFontSize: 16,
    marginBottom: '1.5rem',
    h1: {
      fontFamily: 'Montserrat',
      fontSize: '6.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: '3rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '1.95rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.55rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    button: {
      fontFamily: 'Roboto',
      fontSize: '1.1rem',
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
      }
    },
    MuiGridItem: {
      defaultProps: {
        padding: 0
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: '1440px',
        height: '100vh'
      }
    }
  }
});

const theme = responsiveFontSizes(baseTheme);

export default theme;