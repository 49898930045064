import { Box, Chip, Stack, Typography } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';

const Post = ({ title, caption, image, tags, link, github }) => {
  return (
    <Box id="card-wrapper" component="div" sx={{ position: 'relative', display: 'block', width: '100%', minHeight: '40vh', alignItems: 'center' }}>
      <Stack sx={{ width: '100%' }}>
        {link ? (
          <Box component="a" href={link} target="_blank" rel="noreferrer">
            <Box id="post-card-image" component="img" src={image} sx={{ position: 'relative', display: 'block', width: '100%', maxHeight: '50vh', objectFit: 'cover', objectPosition: 'top', border: '1px solid #FFFFFF', borderRadius: '24px', transition: 'transform 500ms, border-radius 500ms', '&:hover': {borderRadius: '20px', transform: 'scale(1.01)'} }}/>
          </Box>
          ) : (
            <Box id="post-card-image" component="img" src={image} sx={{ position: 'relative', display: 'block', width: '100%', maxHeight: '50vh', objectFit: 'cover', objectPosition: 'top', border: '1px solid #FFFFFF', borderRadius: '24px', transition: 'transform 500ms, border-radius 500ms', '&:hover': {borderRadius: '20px', transform: 'scale(1.01)'} }}/>
          )}
        <Box id="post-card-details" component="div" sx={{ p: '2%', position: 'relative', display: 'flex', width: '100%', height: '100%', flexDirection: 'column' }}>
          <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {title && (
              <Typography id="post-card-details-title" variant="h4" component="h4" align="left" sx={{ flex: 1, my: 1 }}>
                {title}
              </Typography>
            )}
            {github && (
              <Typography id="post-card-details-title-githublink" variant="body1" component="p" align="right" sx={{ flex: 1, my: 1 }}>
                <Box component="a" href={github} target="_blank" rel="noreferrer"><GitHubIcon sx={{ pr: 3, height: '40px', width: '40px', transition: 'color 300ms', '&:hover': { color: '#6e5494' } }}/></Box>
              </Typography>
            )}
          </Box>
          {caption && (
            <Typography id="post-card-details-caption" variant="body1" component="p" align="left" sx={{ flex: 1, my: 1 }}>
              {caption}
            </Typography>
          )}
          {tags && (
            <Box component="div" sx={{ flex: 1, my: '1' }}>
              {tags.map((tag, index) => (
                <Chip key={index} label={tag} sx={{ mr: '5px' }} />
              ))}
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  )
}

export default Post;