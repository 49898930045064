import { Box } from '@mui/material';
import Hero from '../components/organisms/Hero';
import Content from '../components/organisms/Content';
import Footer from '../components/organisms/Footer';
import Chatbot from '../components/organisms/Chatbot';

const Home = () => {

  return (
    <Box sx={{ backgroundColor: 'background.default', zIndex: '-100' }}>
      <Hero />
      <Content />
      <Footer />
      <Chatbot />
    </Box>
  )
}

export default Home;