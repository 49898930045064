import { Avatar, Box, Container, Typography } from '@mui/material';

const Hero = () => {
  return (
    <Container sx={{ position: 'relative', maxWidth: '1440px', height: '100vh' }}>
      <Box id="hero-text-container" sx={{ display: 'flex', position: 'relative', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'flex-start' }}>
        <Avatar src='images/alex-bw.png' sx={{ width: 150, height: 150, my: 4 }}/>
        <Typography variant="h1" component="h1" align="left" sx={{ zIndex: '2', my: 3 }}>
          <strong>I'm Alex.</strong><br />
          Software Engineer &<br />
          Tech Leader.
        </Typography>
        <Typography variant="h2" component="h2" align="left" sx={{ zIndex: '2', my: 3 }}>
          I build things for the web and talk about healthcare.
        </Typography>
      </Box>
    </Container>
  )
}

export default Hero;