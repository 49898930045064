import { Container, Stack, Typography } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';


const Footer = () => {
  return (
    <Container sx={{ position: 'relative', width: '100%', height: 'auto', mt: 5, mb: 6 }}>
      <Stack direction="row" spacing={2} sx={{ display: 'flex', pt: "2%", justifyContent: 'center' }}>
        <a href="https://www.github.com/anoboa2" target="_blank" rel="noreferrer" style={{ color: '#888'}}><GitHubIcon sx={{ height: '50px', width: '50px', transition: 'color 300ms', '&:hover': { color: '#6e5494' } }}/></a>
        <a href="https://www.linkedin.com/alexandernoboa" target="_blank" rel="noreferrer" style={{ color: '#888'}}><LinkedInIcon color="white" alt="LinkedIn Logo" sx={{ height: '50px', width: '50px', transition: 'color 300ms', '&:hover': { color: '#0077B5' } }}/></a>
        {/* <a href="https://www.twitter.com" target="_blank" rel="noreferrer" style={{ color: '#888'}}><TwitterIcon color="white" alt="Twitter Logo" sx={{ height: '50px', width: '50px', transition: 'color 300ms', '&:hover': { color: '#1DA1F2' } }}/></a> */}
        <a className="xicon" href="https://www.x.com/14anob" target="_blank" rel="noreferrer"><img src="images/Twitter-X.svg" alt="X Logo" width="50px" height="50px"/></a>
      </Stack>
      <Typography variant="body2" component="p" align="center" sx={{ mt: 4, color: '#888' }}>
        © 2023 Alex Noboa
      </Typography>
    </Container>
  )
}

export default Footer;