import { Box, IconButton, Typography } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const InterviewButton = ({ clickCallback, chatState }) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        position: 'fixed',
        bottom: '50px',
        right: '50px',
        alignItems: 'center',
        justifyItems: 'center'
      }}
    >
      <IconButton
        onClick={clickCallback}
        sx={{
          display: 'block',
          height: 80,
          width: 80,
          display: chatState ? 'none': 'block',
          transition: 'background-color 250ms',
          '&:hover': {backgroundColor: 'whitesmoke'}
        }}
      >
        <QuestionAnswerIcon
          sx={{
            height: 40,
            width: 40
          }}
        />
      </IconButton>
    </Box>
  )
}

export default InterviewButton;